<template>
  <div>
    <ul class="multiple-image">
      <li v-for="(item, index) in imageUrl" :key="index">
        <img :src="item" alt="背景图片">
        <div class="close el-icon-circle-close" @click="remove(index)"></div>
      </li>
      <li class="fileupload">
        <input name="file" type="file" @change="change($event)" multiple :disabled="disabled">
        <span class="el-icon-upload">请上传图片</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { imageUploadRequest } from '@/api/upload'
export default {
  name: 'ImageUpload',
  props: {
    imgList: {
      type: Array,
      default: undefined
    },
    maxcount: {
      type: Number,
      default: 5
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imageUrl: []
    }
  },
  computed: {
    singleImageUrl() {
      if (!this.multiple) {
        return this.imageUrl
      }
      return ''
    }
  },
  watch: {
    imgList(newVal) {
      if (newVal) {
        console.log(newVal)
        this.imageUrl = newVal
      }
    }
  },
  created() { },
  methods: {
    async change(e) {
      console.log(e)
      let maxcount = this.maxcount
      if (this.imageUrl.length >= maxcount) {
        this.$message.warning('最多上传' + maxcount + '张图片')
        return
      }
      if (!e.currentTarget.files.length) {
        return
      }
      let fileArr = e.currentTarget.files
      for (let i = 0; i < fileArr.length; i++) {
        if (fileArr[i].type.indexOf('image') < 0) {
          this.$message.warning('只能上传图片！')
          return
        }
        let data = new FormData()
        data.append('file', fileArr[i])
        let imageUrl = this.imageUrl
        await imageUploadRequest(data).then(res => {
          if (res.code == 1) {
            imageUrl.push(res.data.accessAddress)
            this.multiple = true
            this.imageUrl = imageUrl
          } else {
            this.$message.warning(res.msg)
          }
        })
      }
      this.$emit('upload', this.imageUrl)
    },
    remove(index) {
      this.imageUrl.splice(index, 1)
    }
  }
}
</script>
<style lang="less" scoped>
.multiple-image {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  width: 1100px;
  height: auto;

  li {
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: center;
    border: 1px dashed #dcdfe6;
    border-radius: 5px;
    margin: 0 15px 15px 0;
    position: relative;
  }

  img {
    display: block;
    width: 300px;
  }

  .fileupload {
    position: relative;
  }

  .close {
    position: absolute;
    right: -15px;
    top: -15px;
    font-size: 30px;
    background: #fff;
    color: #666;
  }
}

.fileupload {
  display: inline-block;
  width: 100%;
  border: 1px dashed #dcdfe6;
  border-radius: 5px;
  cursor: pointer;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  vertical-align: middle;
  height: 125px !important;
  line-height: 125px !important;

  span {
    font-size: 15px;
    vertical-align: middle;
    color: #c0c4cc;

    &::before {
      font-size: 30px;
      margin-right: 10px;
    }
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.is-error {
  .fileupload {
    border-color: #f56c6c;
  }
}
</style>
