<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品信息</el-breadcrumb-item>
            <el-breadcrumb-item>添加/编辑</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">
        <el-form-item label="序号" :error="$v.form.sorder.$error ? '请输入序号' : ''">
          <el-input v-model.number="form.sorder" type="number"></el-input>
        </el-form-item>
        <el-form-item label="商品类型" :error="$v.form.producttypeid.$error ? '请选择所属商品类型' : ''">
          <el-select v-model="form.producttypeid" filterable allow-create default-first-option placeholder="请选择商品类型">
            <el-option v-for="item in classify" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属商家" :error="$v.form.shopid.$error ? '请选择所属商家' : ''">
          <el-select v-model="form.shopid" filterable allow-create default-first-option placeholder="请选择所属商家">
            <el-option v-for="item in shopdata" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品标题" :error="$v.form.name.$error ? '请输入商品标题' : ''">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="商品标签">
          <el-input v-model="form.lables"></el-input>英文逗号分隔多个
        </el-form-item>

        <!-- <el-form-item label="发网ERP商品SKUID" :error="$v.form.skuCode.$error ? '请输入发网ERP商品SKUID' : ''">
          <el-input v-model="form.skuCode"></el-input>
        </el-form-item> -->

        <el-form-item label="商品规格名称">
          <el-input v-model="form.skuname"></el-input>
        </el-form-item>

        <el-form-item label="首页图" :error="$v.form.homeImg.$error ? '请上传首页图' : ''">
          <image-upload @upload="imageUpload" :image="form.homeImg" />
          <span>1000*163px </span>
        </el-form-item>
        <el-form-item label="轮播图组" :error="$v.form.homeImgarray.$error ? '请上传详情轮播图组' : ''">
          <images-upload @upload="bannerUpload" :imgList="form.homeImgarray" />
          <span>1000*163px </span>
        </el-form-item>
        <el-form-item label="市场价" :error="$v.form.pricedoor.$error ? '请输入市场价' : ''">
          <el-input-number v-model="form.pricedoor" :precision="2" :step="0.01"></el-input-number>
        </el-form-item>
        <el-form-item label="商城价" :error="$v.form.price.$error ? '请输入商城价' : ''">
          <el-input-number v-model="form.price" :precision="2" :step="0.01"></el-input-number>
        </el-form-item>
        <el-form-item label="库存" :error="$v.form.stock.$error ? '请输入库存' : ''">
          <el-input v-model.number="form.stock"></el-input>
        </el-form-item>
        <el-form-item label="虚拟销量">
          <el-input v-model.number="form.xnsalenum"></el-input>
        </el-form-item>
        <el-form-item label="商品性质">
          <el-radio-group v-model="form.ptype">
            <el-radio :label="0">普通商品</el-radio>
            <!-- <el-radio :label="1">限时购</el-radio> -->

          </el-radio-group>
        </el-form-item>
        <block v-if="form.ptype == 1">
          <el-form-item label="限时购价格">
            <el-input-number v-model="form.limitedtimeprice" :precision="2" :step="0.01"></el-input-number>
          </el-form-item>
          <el-form-item label="限时购开始时间">
            <el-date-picker v-model="form.limitedtimestart" type="datetime" placeholder="选择日期时间" align="right"
              format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
          <el-form-item label="限时购结束时间">
            <el-date-picker v-model="form.limitedtimeend" type="datetime" placeholder="选择日期时间" align="right"
              format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
        </block>
        <el-form-item label="状态">
          <el-radio-group v-model="form.states">
            <el-radio :label="0">下架</el-radio>
            <el-radio :label="1">上架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="首页置顶大框推荐" v-if="false">
          <el-radio-group v-model="form.istoplarge">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="大框推荐显示图" v-if="form.istoplarge == 1">
          <image-upload @upload="imageUploadistoplargeimg" :image="form.istoplargeimg" />
          <span>353px*559px</span>
        </el-form-item>
        <el-form-item label="文案1" v-if="form.istoplarge == 1">
          <el-input v-model="form.istoplargelable1"></el-input>
        </el-form-item>
        <el-form-item label="文案2" v-if="form.istoplarge == 1">
          <el-input v-model="form.istoplargelable2"></el-input>
        </el-form-item>
        <el-form-item label="文案3" v-if="form.istoplarge == 1">
          <el-input v-model="form.istoplargelable3"></el-input>
        </el-form-item>
        <el-form-item label="首页小框推荐" v-if="false">
          <el-radio-group v-model="form.istoplittle">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="文案1" v-if="form.istoplittle == 1">
          <el-input v-model="form.istoplittlelable1"></el-input>
        </el-form-item>
        <el-form-item label="文案2" v-if="form.istoplittle == 1">
          <el-input v-model="form.istoplittlelable2"></el-input>
        </el-form-item>
        <el-form-item label="内容页推荐">
          <el-radio-group v-model="form.istopcontent">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="width:100%" label="描述" :error="$v.form.contensts.$error ? '请输入描述' : ''">
          <tinymce v-model="form.contensts" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Tinymce from "@/components/tinymce";
import ImageUpload from "@/components/imageUpload";
import ImagesUpload from "@/components/imagesUpload";
import FileUpload from "@/components/fileUpload";
import { editRequest, getdatilRequest } from "@/api/zrproduct";
import { getSelectRequest } from "@/api/zrproducttype";
import { getSelectshopRequest } from "@/api/zrshop";
import { required } from "vuelidate/lib/validators";
import utils from "@/utils/util";
export default {
  name: "Articletypeedit",
  data() {
    return {
      classify: [],
      shopdata: [],
      form: {
        id: 0,
        producttypeid: "",
        homeImgarray: []
      },
      producttypeidtext: [],
      educationdata: [],
      constellationdata: [],
      zodiacdata: [],
      typeId: 0
    };
  },
  components: {
    "image-upload": ImageUpload,
    "images-upload": ImagesUpload,
    "file-upload": FileUpload,
    tinymce: Tinymce
  },
  created() {
    this.form.id = this.$route.query["id"];
    this.typeId = this.$route.query["typeId"];

    this.getSelect();
    this.getSelectshop();
    this.getDetail();
  },
  methods: {
    back() {
      this.$router.back();
    },
    bannerUpload(url) {
      console.log(url);
      this.form.homeImgarray = url;
    },
    imageUpload(url) {
      this.form.homeImg = url;
    },
    imageUploadistoplargeimg(url) {
      this.form.istoplargeimg = url;
    },

    getSelect() {
      getSelectRequest().then(res => {
        console.log(res);
        this.classify = res.data;
      });
    },
    getSelectshop() {
      getSelectshopRequest().then(res => {
        this.shopdata = res.data;
      });
    },
    getDetail() {
      getdatilRequest({
        Id: this.form.id
      }).then(res => {
        res.data.homeImgarray = utils.ImgListFormat(res.data.homeImgarray);
        if (this.form.id == 0) {
          res.data.producttypeid = parseInt(this.typeId);
          res.data.shopid = "";
          if (parseInt(this.typeId) == 0) {
            res.data.producttypeid = "";
          }
        }

        this.form = res.data;
      });
    },

    save() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form));

        params.homeImgarray = utils.uploadImgFormat(params.homeImgarray);
        params.homeImg = utils.removeUrl(params.homeImg);
        params.istoplargeimg = utils.removeUrl(params.istoplargeimg);
        params.isintegral = 0;
        editRequest(params).then(res => {
          if (res.code == 1) {
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },

      sorder: {
        required
      },
      contensts: {
        required
      },
      price: {
        required
      },
      shopid: {
        required
      },
      stock: {
        required
      },
      homeImg: {
        required
      },
      states: {
        required
      },
      producttypeid: { required },
      homeImgarray: { required },
      pricedoor: { required }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
